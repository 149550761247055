import { TFunction } from "next-i18next";

import { createAvailableSymbolsRegex } from "./available-symbols-regex";
import { AvailableSymbolsValidatorParams } from "./types";
import {
    AVAILABLE_SYMBOLS_ONLY_NUMBERS_AND_LETTERS_TRANSLATE_KEY,
    AVAILABLE_SYMBOLS_TRANSLATE_KEY,
} from "./constants";

const generateSymbolExplanations = (t: TFunction): Record<string, string> => ({
    s: t("validationError.space", "space"),
});

export const availableSymbolsValidator: AvailableSymbolsValidatorParams = ({
    validation,
    validationRule,
    t,
}) => {
    const symbolExplanations = generateSymbolExplanations(t);

    const { availableSymbols = [] } = validationRule;

    const errorMessage = !availableSymbols.length
        ? t(
              AVAILABLE_SYMBOLS_ONLY_NUMBERS_AND_LETTERS_TRANSLATE_KEY,
              "The field can contain numbers and letters.",
          )
        : t(
              AVAILABLE_SYMBOLS_TRANSLATE_KEY,
              "The field can contain numbers, letters and the following symbols: {{symbols}}",
              {
                  symbols: availableSymbols
                      .map((symbol) => symbolExplanations[symbol] ?? symbol)
                      .join(" "),
              },
          );

    return validation.regex(
        createAvailableSymbolsRegex(availableSymbols),
        errorMessage,
    );
};
