import { AvailableSymbols } from "./types";

export const AVAILABLE_SYMBOLS_TRANSLATE_KEY =
    "validationError.canContainNumbersAndLettersAndSymbols";
export const AVAILABLE_SYMBOLS_ONLY_NUMBERS_AND_LETTERS_TRANSLATE_KEY =
    "validationError.canContainNumbersAndLetters";

export const REPORT_NAME_AVAILABLE_SYMBOLS: AvailableSymbols[] = [
    "-",
    "s",
    '"',
    "#",
    "'",
    "(",
    ".",
    ")",
    "*",
    "+",
    ",",
    ".",
    ":",
    ";",
    "<",
    "=",
    ">",
    "|",
    "}",
    "{",
    "~",
    "/",
    "^",
    "_",
    "\\",
    "[",
    "]",
];

export const PROVIDER_NAME_AVAILABLE_SYMBOLS: AvailableSymbols[] = ["s", "-"];

export const GAME_NAME_AVAILABLE_SYMBOLS: AvailableSymbols[] = [
    "-",
    ".",
    ",",
    ":",
    "'",
    "&",
    "!",
    "(",
    ")",
    '"',
    "#",
    "*",
    "+",
    ";",
    "=",
    "s",
    "_",
];

export const METRIC_TITLE_DESCRIPTION_AVAILABLE_SYMBOLS: AvailableSymbols[] = [
    "-",
    "s",
    '"',
    "#",
    "'",
    "(",
    ")",
    "*",
    "+",
    ",",
    ".",
    ":",
    ";",
    "<",
    "=",
    ">",
    "|",
    "}",
    "{",
    "~",
    "/",
    "^",
    "_",
    "\\",
    "[",
    "]",
    "`",
];

export const REPORT_DESCRIPTION_AVAILABLE_SYMBOLS: AvailableSymbols[] = [
    ...REPORT_NAME_AVAILABLE_SYMBOLS,
    "`",
];

export const GAME_TAG_AVAILABLE_SYMBOLS: AvailableSymbols[] = [
    "s",
    ".",
    ",",
    ":",
    "'",
    "&",
    "!",
    "(",
    ")",
    "_",
    '"',
    "#",
    "*",
    "+",
    ";",
    "=",
    "-",
];
